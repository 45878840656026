import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import initApollo from "../data/apollo";
import gql from 'graphql-tag';
import { INTERSTITIAL_REDIRECT_TIME } from "../constants/app";
import BroadcastChannel from 'broadcast-channel';
import { removeShoSessionTokens } from "../utils/sessionHelper";
import { getChannel } from "../utils/sessionStorageHelper";
import { openLoginModal } from "./user";
var initialState = {
  merchant: null,
  fetchingClickUrl: false,
  clickUrl: null,
  clickUrlError: false,
  showCookieWarning: false,
  popUpError: false
};
var actionTypes = {
  SET_SHOP_MERCHANT: 'SET_SHOP_MERCHANT',
  CLEAR_SHOP_MERCHANT: 'CLEAR_SHOP_MERCHANT',
  CLICK_URL_REQUEST: 'CLICK_URL_REQUEST',
  CLICK_URL_SUCCESS: 'CLICK_URL_SUCCESS',
  CLICK_URL_ERROR: 'CLICK_URL_ERROR',
  CLICK_URL_TOKEN_CLEAR: 'CLICK_URL_TOKEN_CLEAR',
  CLEAR_CLICK_URL: 'CLEAR_CLICK_URL',
  OPEN_COOKIE_WARNING: 'OPEN_COOKIE_WARNING',
  CLOSE_COOKIE_WARNING: 'CLOSE_COOKIE_WARNING',
  POP_UP_ERROR: 'POP_UP_ERROR'
};
export var clickUrlRequest = function clickUrlRequest() {
  return {
    type: actionTypes.CLICK_URL_REQUEST
  };
};
export var clickUrlSuccess = function clickUrlSuccess(clickUrl) {
  return {
    type: actionTypes.CLICK_URL_SUCCESS,
    clickUrl: clickUrl
  };
};
export var clickUrlError = function clickUrlError() {
  return {
    type: actionTypes.CLICK_URL_ERROR
  };
};
export var clickUrlTokenClear = function clickUrlTokenClear() {
  return {
    type: actionTypes.CLICK_URL_TOKEN_CLEAR
  };
};
export var clearClickUrl = function clearClickUrl() {
  return {
    type: actionTypes.CLEAR_CLICK_URL
  };
};
export var popUpError = function popUpError() {
  return {
    type: actionTypes.POP_UP_ERROR
  };
};
export var setShopMerchant = function setShopMerchant(merchant) {
  return {
    type: actionTypes.SET_SHOP_MERCHANT,
    merchant: merchant
  };
};
export var clearShopMerchant = function clearShopMerchant(merchant) {
  return {
    type: actionTypes.CLEAR_SHOP_MERCHANT,
    merchant: merchant
  };
};
export var openCookieWarning = function openCookieWarning() {
  return {
    type: actionTypes.OPEN_COOKIE_WARNING
  };
};
export var closeCookieWarning = function closeCookieWarning() {
  return {
    type: actionTypes.CLOSE_COOKIE_WARNING
  };
};
export var retryAfterSessionRemoval = function retryAfterSessionRemoval(window) {
  var _window$location;
  // window.location.search can be ''
  if ((window === null || window === void 0 ? void 0 : (_window$location = window.location) === null || _window$location === void 0 ? void 0 : _window$location.search) !== undefined) {
    var queries = new URLSearchParams(window.location.search);
    var refreshToken = parseInt(queries.get('reftok'), 10);
    if (refreshToken === 2) {
      return false;
    }
    var reftokNew = 1; // init
    if (refreshToken) {
      reftokNew = 2;
    } // increase reftok by 1
    queries.set('reftok', reftokNew);
    window.location.search = queries.toString();
    return true;
  }
  return false;
};
export var fetchClickUrl = function fetchClickUrl(merchantIdOLM, fromPlugin, couponId) {
  return function (dispatch) {
    dispatch(clickUrlRequest());
    var broadcastChannel = new BroadcastChannel('qshopping');
    var channelId = getChannel();
    var getClickUrlMutation;
    if (couponId) {
      getClickUrlMutation = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      mutation getClickUrlMutation(\n        $merchantIdOLM: Int\n        $fromPlugin: Boolean\n        $couponId: String\n        $channelId: String\n      ) {\n        getClickUrl(\n          merchantIdOLM: $merchantIdOLM\n          fromPlugin: $fromPlugin\n          couponId: $couponId\n          channelId: $channelId\n        ) {\n          clickUrl\n        }\n      }\n    "])));
    } else {
      getClickUrlMutation = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      mutation getClickUrlMutation($merchantIdOLM: Int, $fromPlugin: Boolean, $channelId: String) {\n        getClickUrl(merchantIdOLM: $merchantIdOLM, fromPlugin: $fromPlugin, channelId: $channelId) {\n          clickUrl\n        }\n      }\n    "])));
    }
    var timeElapsed = 0;
    var interstitialTimer = setInterval(function () {
      timeElapsed += 1000;
    }, 1000);
    var apollo = initApollo();
    apollo.mutate({
      mutation: getClickUrlMutation,
      variables: {
        merchantIdOLM: merchantIdOLM,
        fromPlugin: !!fromPlugin,
        couponId: couponId,
        channelId: channelId
      }
    }).then(function (_ref) {
      var errors = _ref.errors,
        clickUrl = _ref.data.getClickUrl.clickUrl;
      if (errors) {
        removeShoSessionTokens();
        dispatch(clickUrlTokenClear());
        if (!retryAfterSessionRemoval()) {
          dispatch(clickUrlError());
        }
      } else if (clickUrl) {
        dispatch(clickUrlSuccess(clickUrl));
        broadcastChannel.postMessage('success');

        // Update already opened window with the new clickUrl after several seconds
        if (timeElapsed < INTERSTITIAL_REDIRECT_TIME) {
          setTimeout(function () {
            window.location.replace(clickUrl);
            dispatch(clearShopMerchant());
            dispatch(clearClickUrl());
            clearInterval(interstitialTimer);
          }, INTERSTITIAL_REDIRECT_TIME - timeElapsed);
        } else {
          window.location.replace(clickUrl);
          dispatch(clearShopMerchant());
          dispatch(clearClickUrl());
        }
      }
    }).catch(function () {
      removeShoSessionTokens();
      dispatch(clickUrlTokenClear());
      if (!retryAfterSessionRemoval()) {
        dispatch(clickUrlError());
      }
    });
  };
};
export var clickToShop = function clickToShop(merchantId, merchantIdOLM, fromPlugin, couponId) {
  return function (dispatch, getState) {
    // Show warning modal if user has cookies disabled on their browser
    if (window.navigator.cookieEnabled === false) {
      dispatch(openCookieWarning());
      return;
    }
    var _getState = getState(),
      _getState$user = _getState.user,
      authenticated = _getState$user.authenticated,
      authorized = _getState$user.authorized;
    if (authenticated || authorized) {
      dispatch(fetchClickUrl(merchantIdOLM, fromPlugin, couponId));
    } else {
      dispatch(setShopMerchant({
        merchantId: merchantId,
        merchantIdOLM: merchantIdOLM,
        fromPlugin: fromPlugin
      }));
      dispatch(openLoginModal(window.location.href));
    }
  };
};
export var shopReducer = function shopReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actionTypes.CLICK_URL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingClickUrl: true,
        clickUrlError: false,
        popUpError: false
      });
    case actionTypes.CLICK_URL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        clickUrl: action.clickUrl,
        fetchingClickUrl: false,
        clickUrlError: false
      });
    case actionTypes.CLICK_URL_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        clickUrl: null,
        clickUrlError: true
      });
    case actionTypes.CLEAR_CLICK_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        clickUrl: null,
        clickUrlError: false
      });
    case actionTypes.SET_SHOP_MERCHANT:
      return _objectSpread(_objectSpread({}, state), {}, {
        merchant: action.merchant,
        clickUrlError: false
      });
    case actionTypes.CLEAR_SHOP_MERCHANT:
      return _objectSpread(_objectSpread({}, state), {}, {
        merchant: null,
        clickUrlError: false,
        popUpError: false
      });
    case actionTypes.OPEN_COOKIE_WARNING:
      return _objectSpread(_objectSpread({}, state), {}, {
        showCookieWarning: true
      });
    case actionTypes.CLOSE_COOKIE_WARNING:
      return _objectSpread(_objectSpread({}, state), {}, {
        showCookieWarning: false
      });
    case actionTypes.POP_UP_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        popUpError: true
      });
    default:
      return state;
  }
};