var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { layoutSizes } from "../../constants/css";
var Wrapper = styled.div.withConfig({
  displayName: "Container__Wrapper",
  componentId: "sc-1l4nhp9-0"
})(["max-width:", ";margin:0 auto;", " ", " position:relative;padding:", ";@media only screen and (min-width:", "){padding:0 15px;}"], layoutSizes.containerWidth, function (props) {
  return props.wideView && "\n    max-width: ".concat(layoutSizes.containerWideWidth, ";\n  ");
}, function (props) {
  return props.veryWideView && "\n\n    max-width: ".concat(layoutSizes.containerVeryWideWidth, ";\n  ");
}, function (props) {
  return props.mobilePadding || '0 15px';
}, layoutSizes.tabletAndDesktopWidth);
var Container = function Container(props) {
  return __jsx(Wrapper, props, props.children);
};
export default Container;