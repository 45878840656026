import styled from 'styled-components';
import { colours, fontSizes, customFonts, layoutSizes, responsiveQuery, zIndexes } from "../../../constants/css";
import { Button } from "../../_global";
export var LoginButton = styled(Button).withConfig({
  displayName: "style__LoginButton",
  componentId: "sc-1bugxca-0"
})(["border-radius:0;height:60px;margin:0;@media only screen and ", "{display:", ";}"], responsiveQuery.mobileNew, function (props) {
  return !props.isMobileBrowser && 'none';
});
export var Info = styled.button.withConfig({
  displayName: "style__Info",
  componentId: "sc-1bugxca-1"
})(["display:inline-flex;align-items:center;justify-content:space-between;cursor:pointer;background:none;border:0;font-family:inherit;font-size:inherit;text-align:inherit;position:relative;padding:0;", " @media only screen and (min-width:", "){&::before{display:none;}}"], function (props) {
  return props.showIndicator && "\n\t\t&::before {\n\t\t\tcontent: \"\";\n\t\t\tdisplay: inline-block;\n\t\t\twidth: 4px;\n\t\t\theight: 4px;\n\t\t\tbackground: #000000;\n\t\t\tz-index: 1;\n\t\t\tborder-radius: 50%;\n\t\t\tborder: 4px solid ".concat(colours.qantasAqua, ";\n\t\t\tposition: absolute;\n\t\t\ttop: -3px;\n\t\t\tright: 0;\n\t\t}\n\t");
}, layoutSizes.desktopWidth);
export var ProfileBlock = styled.div.withConfig({
  displayName: "style__ProfileBlock",
  componentId: "sc-1bugxca-2"
})(["white-space:nowrap;> span{position:absolute;right:0;top:25%;}"]);
export var Name = styled.p.withConfig({
  displayName: "style__Name",
  componentId: "sc-1bugxca-3"
})(["display:none;font-family:", ";font-size:", ";color:inherit;margin:0;@media only screen and (min-width:", "){display:block;}"], customFonts.ciutadellaMed, fontSizes.xs, layoutSizes.desktopWidth);
export var Points = styled.p.withConfig({
  displayName: "style__Points",
  componentId: "sc-1bugxca-4"
})(["display:none;color:inherit;margin:0;text-align:right;font-family:", ";span{font-size:", ";font-family:", ";}@media only screen and (min-width:", "){display:block;}"], customFonts.ciutadellaMed, fontSizes.xs, customFonts.ciutadellaReg, layoutSizes.desktopWidth);
export var Avatar = styled.span.withConfig({
  displayName: "style__Avatar",
  componentId: "sc-1bugxca-5"
})(["display:inline-flex;align-items:center;justify-content:center;height:30px;width:30px;@media only screen and (min-width:", "){margin-right:0.5rem;}"], layoutSizes.desktopWidth);
export var Detail = styled.div.withConfig({
  displayName: "style__Detail",
  componentId: "sc-1bugxca-6"
})(["position:absolute;top:100%;right:0;width:300px;background:", ";z-index:2;margin-top:7px;text-align:left;box-shadow:0 2px 3px 0 rgba(0,0,0,0.2),0 10px 10px 0 rgba(0,0,0,0.05);h2{font-size:", ";font-family:", ";color:#000000;font-weight:normal;padding:1rem;margin:0;}&::before{position:absolute;right:0.6rem;transform:translateY(-100%);content:'';width:0;height:0;border-left:6px solid transparent;border-right:6px solid transparent;border-bottom:6px solid ", ";}@media only screen and (min-width:", "){&::before{right:1.25rem;}}"], colours.qantasWhite, fontSizes.xl, customFonts.ciutadellaMed, colours.qantasWhite, layoutSizes.desktopWidth);
export var MemberName = styled.p.withConfig({
  displayName: "style__MemberName",
  componentId: "sc-1bugxca-7"
})(["font-family:", ";font-size:", ";color:#000000;"], customFonts.ciutadellaMed, fontSizes.base);
export var LinkList = styled.ul.withConfig({
  displayName: "style__LinkList",
  componentId: "sc-1bugxca-8"
})(["padding-left:0;margin:0;"]);
export var LinkItem = styled.li.withConfig({
  displayName: "style__LinkItem",
  componentId: "sc-1bugxca-9"
})(["list-style:none;line-height:1;margin-bottom:0.25rem;a{color:", ";text-decoration:none;font-size:", ";}"], colours.qantasRed, fontSizes.small);
export var RememberMe = styled.div.withConfig({
  displayName: "style__RememberMe",
  componentId: "sc-1bugxca-10"
})(["color:#000000;font-size:", ";padding:0 1rem 0.75rem 1rem;a{text-decoration:underline;cursor:pointer;}"], fontSizes.small);
export var DetailSection = styled.div.withConfig({
  displayName: "style__DetailSection",
  componentId: "sc-1bugxca-11"
})(["background:", ";padding:0.8rem 1rem;margin-bottom:", ";button{margin-top:0.5rem;margin-bottom:0.5rem;}", "{margin-bottom:0.25rem;}p{margin:0;}"], function (props) {
  return props.background ? colours.qantasIceWhite : 'none';
}, function (props) {
  return props.background ? '5px' : '0';
}, MemberName);
export var LastDetails = styled(DetailSection).withConfig({
  displayName: "style__LastDetails",
  componentId: "sc-1bugxca-12"
})(["padding-top:0;a{text-decoration:none;}"]);
export var Profile = styled.div.withConfig({
  displayName: "style__Profile",
  componentId: "sc-1bugxca-13"
})(["position:relative;text-align:right;height:", ";margin-right:0;z-index:", ";", "{visibility:", ";}", "{visibility:", ";@media only screen and ", "{display:none;}}@media only screen and ", "{margin-right:5px;}"], function (props) {
  return props.visible ? '100%' : 0;
}, zIndexes.desktopProfile, LoginButton, function (props) {
  return props.visible ? 'visible' : 'collapse';
}, Info, function (props) {
  return props.visible ? 'visible' : 'collapse';
}, responsiveQuery.mobileNew, responsiveQuery.largerThanMobileNew);
export var ProfileDropdown = styled.div.withConfig({
  displayName: "style__ProfileDropdown",
  componentId: "sc-1bugxca-14"
})(["display:flex;align-items:center;justify-content:flex-end;height:100%;position:relative;top:0;z-index:", ";", "{margin-left:0.5rem;}@media only screen and (min-width:", "){padding:0 1rem;right:0;background:", ";", "{color:", ";}", "{color:", ";margin-left:0;}", " ", "{svg:hover{#circle{stroke:", ";}#profile{fill:", ";}}#circle{stroke:", ";}#profile{fill:", ";}}}"], zIndexes.desktopProfileDropdown, Info, layoutSizes.desktopWidth, function (props) {
  return props.active ? colours.qantasRed : 'transparent';
}, ProfileBlock, function (props) {
  return props.active ? colours.qantasWhite : 'black';
}, Info, function (props) {
  return props.active ? colours.qantasWhite : 'black';
}, function (props) {
  return props.active && "\n\t\t\tbutton:focus {\n\t\t\t\toutline: none;\n\t\t\t}\n\t\t";
}, Avatar, function (props) {
  return props.active ? colours.qantasWhite : colours.qantasRed;
}, function (props) {
  return props.active ? colours.qantasWhite : colours.qantasRed;
}, function (props) {
  return props.active ? colours.qantasWhite : colours.qantasCharcoal;
}, function (props) {
  return props.active ? colours.qantasWhite : colours.qantasCharcoal;
});