/** @format */

import styled from 'styled-components';
import { Container, Button } from "../_global";
import { colours, customFonts, navbarSizes, responsiveQuery, mobileSearchHeight, zIndexes, fontSizes } from "../../constants/css";
var nextIcon = "/static/icons/ico_next.svg";
var backIcon = "/static/icons/ico_back.svg";
var closeIcon = "/static/icons/ico_cross.svg";
var moreIcon = "/static/icons/ico_more.svg";
var downIcon = "/static/icons/ico_down.svg";
var nextIconDesktop = "/static/icons/ico_next_desktop.svg";
export var CatMenuList = styled.ul.withConfig({
  displayName: "style__CatMenuList",
  componentId: "sc-1l9vz8o-0"
})(["display:none;list-style-type:none;padding-left:0;margin:0;background:", ";a{display:block;color:", ";cursor:pointer;}@media only screen and (min-width:", "){position:absolute;z-index:9999;box-shadow:0 1px 2px 0 rgba(0,0,0,0.5);padding:0.75rem 0;a{padding:0.375rem;text-decoration:none;&:hover{color:", ";}}}"], colours.qantasWhite, colours.qantasDarkerGrey, navbarSizes.navbarMode, colours.qantasRed);
export var SubCatMenuList = styled(CatMenuList).withConfig({
  displayName: "style__SubCatMenuList",
  componentId: "sc-1l9vz8o-1"
})(["padding:0.25rem 0;@media only screen and (min-width:", "){padding:1rem 0;top:0;left:100%;min-width:400px;min-height:95%;}"], navbarSizes.navbarMode);
export var SubCatMenuListItem = styled.li.withConfig({
  displayName: "style__SubCatMenuListItem",
  componentId: "sc-1l9vz8o-2"
})(["> a{position:relative;padding:0.75rem 0.75rem 0.75rem 2.3rem;text-decoration:underline;}@media only screen and (min-width:", "){> a{padding:0.5rem 1.5rem;}}"], navbarSizes.navbarMode);
export var CatMenuListItem = styled.li.withConfig({
  displayName: "style__CatMenuListItem",
  componentId: "sc-1l9vz8o-3"
})(["display:", ";> ", "{display:", ";}", " > a{display:block;position:relative;color:inherit;padding:0.75rem 0.75rem 0.75rem 2.3rem;text-decoration:underline;cursor:pointer;", "}", " @media only screen and (min-width:", "){display:block !important;background:transparent;color:", ";position:relative;&:hover{position:static;::after{display:none;}}", " > a{padding-left:0.75rem;padding-right:3rem;text-decoration:none;border:1px solid transparent;&::before{display:none;content:'';position:absolute;left:0;top:0;width:5px;height:100%;background:", ";transform:none;}", "}> ", "{display:none;}&:hover{> ", "{display:block;}> a{color:", ";border:1px solid ", ";&::before,&::after{display:block;}}}}"], function (props) {
  return props.show ? 'block' : 'none';
}, SubCatMenuList, function (props) {
  return props.isSelected ? 'block' : 'none';
}, function (props) {
  return props.highlight && "\n    font-family: ".concat(customFonts.ciutadellaMed, ";\n\n    &:last-child {\n      color: ").concat(colours.qantasRed, ";\n    }\n  ");
}, function (props) {
  return props.hasSubCategories && "\n      &::before {\n        position: absolute;\n        content: \"\";\n        width: 8px;\n        height: 8px;\n        background: url('".concat(moreIcon, "') no-repeat;\n        background-size: contain;\n        top: 50%;\n        transform: translateY(-50%);\n        left: 1rem;\n      }\n    ");
}, function (props) {
  return props.isSelected && "\n    background: ".concat(colours.qantasGrey, ";\n    color: ").concat(colours.qantasWhite, ";\n\n    > a {\n      position: relative;\n      padding: 0.75rem 0.75rem 0.75rem 2.3rem;\n      text-decoration: none;\n\n      &::before {\n        background: url('").concat(closeIcon, "') no-repeat;\n      }\n    }\n  ");
}, navbarSizes.navbarMode, colours.qantasDarkerGrey, function (props) {
  return props.hasSubCategories && "\n      &::after {\n        position: absolute;\n        content: \"\";\n        width: 8px;\n        height: 8px;\n        background: url('".concat(nextIconDesktop, "') no-repeat;\n        background-size: contain;\n        top: 50%;\n        transform: translateY(-50%);\n        right: 1rem;\n      }\n    ");
}, colours.qantasAqua, function (props) {
  return props.hasSubCategories && "\n        &::after {\n          display: none;\n          content: \"\";\n          position: absolute;\n          right: 0;\n          top: 0;\n          width: 20px;\n          height: 100%;\n          background: ".concat(colours.qantasWhite, ";\n          transform: translateX(50%);\n          z-index: 99999;\n        }\n      ");
}, SubCatMenuList, SubCatMenuList, colours.qantasRed, colours.qantasLightGrey);
export var NavBarContainer = styled(Container).withConfig({
  displayName: "style__NavBarContainer",
  componentId: "sc-1l9vz8o-4"
})(["display:flex;padding:0.375rem;position:relative;align-items:center;justify-content:space-between;@media only screen and (min-width:", "){padding:0;}"], navbarSizes.navbarMode);
export var NavWrapper = styled.nav.withConfig({
  displayName: "style__NavWrapper",
  componentId: "sc-1l9vz8o-5"
})(["background-color:", ";"], colours.qantasCharcoal);
export var MobileNavWrapper = styled(NavWrapper).withConfig({
  displayName: "style__MobileNavWrapper",
  componentId: "sc-1l9vz8o-6"
})(["display:", ";"], function (props) {
  return props.showMobile ? 'block' : 'none';
});
export var NavItemList = styled.ul.withConfig({
  displayName: "style__NavItemList",
  componentId: "sc-1l9vz8o-7"
})(["position:absolute;z-index:", ";list-style-type:none;padding-left:0;background:", ";top:100%;left:0;margin-top:0.5rem;margin-left:0.5rem;width:calc(100% - 1rem);box-shadow:0 2px 4px 0 rgba(0,0,0,0.5);&::before{content:' ';width:0;height:0;border-left:8px solid transparent;border-right:8px solid transparent;border-bottom:8px solid ", ";position:absolute;top:0;transform:translateY(-100%);left:2.5rem;}@media only screen and (min-width:", "){display:inline-block;position:relative;width:auto;height:60px;margin:0;box-shadow:none;&::before{display:none;}}"], zIndexes.desktopNavItemList, colours.qantasCharcoal, colours.qantasCharcoal, navbarSizes.navbarMode);
export var NavItem = styled.li.withConfig({
  displayName: "style__NavItem",
  componentId: "sc-1l9vz8o-8"
})(["", " background:", ";> a{position:relative;display:block;font-family:", ";color:", ";text-decoration:none;padding:0.75rem 2.3rem;border-bottom:2px solid white;cursor:pointer;> i{margin-left:0.375rem;}&::after{position:absolute;content:'';width:10px;height:10px;background:url('", "') no-repeat;background-size:contain;top:50%;transform:translateY(-50%);right:1rem;}}> ", "{display:", ";}", " @media only screen and (min-width:", "){display:inline-block;height:inherit;position:static;~ li{display:inline-block;}> a{display:table-cell;vertical-align:middle;height:inherit;padding:0 0.75rem;border:none;> i{position:relative;font-size:7px;top:-1px;}&::before,&::after{display:none;}}&:hover{background-image:linear-gradient( to bottom,", ",", " );}> ", "{display:", ";}}"], function (props) {
  return props.isGlobalNav && 'display: block !important;';
}, function (props) {
  return props.backgroundColor ? props.backgroundColor : props.active && colours.qantasGrey;
}, customFonts.ciutadellaMed, colours.qantasWhite, nextIcon, CatMenuList, function (props) {
  return props.showMobileCatMenu ? 'block' : 'none';
}, function (props) {
  return props.showMobileCatMenu && "\n    ~ li {\n      display: none;\n    }\n\n    > a {\n      &::before {\n        position: absolute;\n        content: \"\";\n        width: 10px;\n        height: 10px;\n        background: url('".concat(backIcon, "') no-repeat;\n        background-size: contain;\n        top: 50%;\n        transform: translateY(-50%);\n        left: 1rem;\n      }\n      &::after {\n        display: none;\n      }\n    }\n  ");
}, navbarSizes.navbarMode, colours.qantasRedGradient, colours.qantasRedEarth, CatMenuList, function (props) {
  return props.showDesktopCatMenu ? 'block' : 'none';
});
export var MenuButton = styled(Button).withConfig({
  displayName: "style__MenuButton",
  componentId: "sc-1l9vz8o-9"
})(["position:relative;line-height:11px;min-width:95px;text-align:left;&::after{content:'';width:10px;height:10px;background:url('", "') no-repeat;background-size:contain;position:absolute;top:50%;transform:translateY(-50%);right:14px;}"], downIcon);
export var MobileGlobalNav = styled.div.withConfig({
  displayName: "style__MobileGlobalNav",
  componentId: "sc-1l9vz8o-10"
})(["position:relative;padding:1.5rem 2.3rem 0.5rem;background:", ";&::after,&::before{bottom:-20px;left:40px;border:solid transparent;content:'';height:0;width:0;position:absolute;pointer-events:none;}&::after{border-color:rgba(255,255,255,0);border-top-color:", ";border-width:10px;margin-left:-10px;z-index:99999;}&::before{border-color:rgba(221,221,221,0);border-top-color:", ";border-width:10px;margin-left:-10px;z-index:99999;}"], colours.qantasWhite, colours.qantasWhite, colours.qantasWhite);
export var SearchContainer = styled.div.withConfig({
  displayName: "style__SearchContainer",
  componentId: "sc-1l9vz8o-11"
})(["display:flex;align-items:center;justify-content:flex-end;width:100%;height:40px;@media only screen and (min-width:", "){width:auto;}"], navbarSizes.navbarMode);
export var ChatContainer = styled.div.withConfig({
  displayName: "style__ChatContainer",
  componentId: "sc-1l9vz8o-12"
})(["margin-right:0.5rem;@media only screen and (min-width:", "){margin-right:1rem;}"], navbarSizes.navbarMode);
export var Desktop = styled.div.withConfig({
  displayName: "style__Desktop",
  componentId: "sc-1l9vz8o-13"
})(["position:absolute;visibility:hidden;@media only screen and (min-width:", "){position:relative;visibility:visible;}"], navbarSizes.navbarMode);
export var Mobile = styled.div.withConfig({
  displayName: "style__Mobile",
  componentId: "sc-1l9vz8o-14"
})(["visibility:visible;@media only screen and (min-width:", "){position:absolute;visibility:hidden;}"], navbarSizes.navbarMode);
export var MobileNavbarContainer = styled.div.withConfig({
  displayName: "style__MobileNavbarContainer",
  componentId: "sc-1l9vz8o-15"
})(["display:", ";height:", "px;position:fixed;top:60px;width:100%;z-index:", ";background:", ";"], function (props) {
  return props.isSearchOpen ? 'block' : 'none';
}, mobileSearchHeight + 1, zIndexes.mobileSearch, colours.qantasWhite);
export var MobileSidebarContainer = styled.nav.withConfig({
  displayName: "style__MobileSidebarContainer",
  componentId: "sc-1l9vz8o-16"
})(["width:100%;height:100%;position:fixed;top:0;left:0;z-index:", ";transform:", ";transition:transform 0.3s ease-in;display:flex;"], zIndexes.mobileMenu, function (props) {
  return props.isMenuOpen ? 'translateX(0)' : 'translateX(-100%)';
});
export var MenuWrapper = styled.div.withConfig({
  displayName: "style__MenuWrapper",
  componentId: "sc-1l9vz8o-17"
})(["flex:1;background-color:", ";opacity:1;overflow-y:scroll;"], colours.qantasCharcoal);
export var MenuRemainderWrapper = styled.div.withConfig({
  displayName: "style__MenuRemainderWrapper",
  componentId: "sc-1l9vz8o-18"
})(["flex-basis:60px;background:", ";opacity:0.9;@media only screen and ", "{flex-basis:45px;}"], colours.qantasRed, responsiveQuery.smallMobile);
export var MenuLoginInfoSection = styled.div.withConfig({
  displayName: "style__MenuLoginInfoSection",
  componentId: "sc-1l9vz8o-19"
})(["min-height:64px;"]);
export var SideNavItemsWrapper = styled.ul.withConfig({
  displayName: "style__SideNavItemsWrapper",
  componentId: "sc-1l9vz8o-20"
})(["margin:0;padding:0;list-style:none;font-size:", ";.global_features_mobile{display:", ";}"], fontSizes.large, function (props) {
  return props.isChannelForBrowser ? 'block' : 'none';
});
export var SideNavItem = styled.li.withConfig({
  displayName: "style__SideNavItem",
  componentId: "sc-1l9vz8o-21"
})(["padding:15px 0 15px;display:flex;background:", ";border-left:", ";a{color:", ";text-decoration:none;flex:1;padding-left:", ";}.more{padding-left:24px;color:", ";flex:1;position:relative;left:", ";}.open-profile{float:right;margin-right:22px;}img{margin-right:17px;}"], function (props) {
  return props.isMoreExpand && colours.qantasSteel;
}, function (props) {
  return props.isMoreExpand && "2px solid ".concat(colours.qantasRed);
}, colours.qantasWhite, function (props) {
  return props.isMoreItems ? '40px' : '24px';
}, colours.qantasWhite, function (props) {
  return props.isMoreExpand && '-2px';
});
export var BonusBadge = styled.span.withConfig({
  displayName: "style__BonusBadge",
  componentId: "sc-1l9vz8o-22"
})(["background:", ";float:right;padding:5px 12px;color:", ";font-size:", ";border-radius:4px;margin-right:17px;max-height:24px;"], colours.qantasTeal, colours.qantasCharcoal, fontSizes.xs);