var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
var shape = "/static/footer-shape.svg";
import { connect } from 'react-redux';
import _ from 'lodash';
import { colours, fontSizes, layoutSizes, customFonts } from "../../constants/css";
import { Container, Button, Icon } from "../_global";
import { getVisaAmexEnrolmentFlag } from "../../lib/optimizelyFlagUtils";
import CMSInfo from "./CMSInfo";
var Section = styled.section.withConfig({
  displayName: "ImportantInfo__Section",
  componentId: "sc-xe1eg5-0"
})(["grid-area:info;background-image:url(", ");background-position:bottom -230px right -150px;background-repeat:no-repeat;background-color:", ";color:", ";padding:60px 0;width:100%;font-size:", ";@media only screen and (min-width:", "){background-position:bottom -230px right 100px;}h4{margin-top:0;}a{color:", ";}"], shape, colours.qantasIceWhite, colours.qantasTermsGrey, fontSizes.xs, layoutSizes.desktopWidth, colours.qantasTermsGrey);
var LinkButton = styled(Button).withConfig({
  displayName: "ImportantInfo__LinkButton",
  componentId: "sc-xe1eg5-1"
})(["text-transform:none;letter-spacing:0;font-size:", ";padding:0;color:", ";margin-top:1rem;font-family:", ";border:none;"], fontSizes.xs, colours.qantasTermsGrey, customFonts.ciutadellaReg);
var ImportantInfo = function ImportantInfo(_ref) {
  var route = _ref.route,
    additionalTerms = _ref.additionalTerms;
  var _useState = useState(false),
    isExpanded = _useState[0],
    setIsExpanded = _useState[1];
  var toggleExpand = function toggleExpand() {
    setIsExpanded(!isExpanded);
  };
  var isVisaAmexEnabled = getVisaAmexEnrolmentFlag();
  return __jsx(Section, {
    className: "important-info"
  }, __jsx(Container, {
    veryWideView: true
  }, __jsx(React.Fragment, null, __jsx("h4", {
    id: "important-information"
  }, "Important information:"), __jsx("p", null, "Individual retailer terms and conditions apply; please check the individual retailer's pages for full terms and conditions."), __jsx("p", null, "Mastercard\xAE and the circles design are registered trademarks of Mastercard\xAE International Incorporated. \xA92021 Mastercard\xAE"), __jsx("p", null, "Visa\xAE is a registered trademark of Visa\xAE International Service Association."), __jsx("p", null, "For Qantas Shopping Online Mall and Card Offers refer to the\xA0", __jsx("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://shopping.qantas.com/terms-and-conditions",
    title: "Qantas Shopping Terms and Conditions"
  }, "Terms & Conditions"), "."), __jsx("p", null, "You must be a\xA0", __jsx("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://www.qantas.com/fflyer/dyn/program/welcome",
    title: "Qantas Frequent Flyer"
  }, "Qantas Frequent Flyer"), "\xA0member to earn and redeem Qantas Points\xA0 \xA0A joining fee may apply. Membership and the earning and redemption of Qantas Points are subject to the Qantas Frequent Flyer \xA0", __jsx("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://www.qantas.com/au/en/frequent-flyer/discover-and-join/terms-and-conditions.html",
    title: "Qantas Frequent Flyer Terms and Conditions"
  }, "Terms and Conditions")), route === '/partner-host' && __jsx("div", {
    dangerouslySetInnerHTML: {
      __html: additionalTerms
    }
  }), isExpanded && __jsx(CMSInfo, null), __jsx(LinkButton, {
    onPress: toggleExpand,
    link: true
  }, isExpanded ? 'View less ' : 'View all important information ', __jsx(Icon, {
    iconType: isExpanded ? 'caretUp' : 'caretDown',
    size: "xxs"
  })))));
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var offers = _ref2.offers;
  return {
    additionalTerms: _.get(offers, ['shopping:join:wow', 0, 'termsAndConditions'], '')
  };
};
export default connect(mapStateToProps)(ImportantInfo);