import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
/** @format */

import initApollo from "../data/apollo";
import gql from 'graphql-tag';
import decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { getDaysFromTodayDate } from "./dateUtils";
import { LSL_TOKEN_NAME, SHO_BONUS_FAVOURITES_CALLOUT_COOKIE_NAME, SHO_BONUS_BANNER_CALLOUT_COOKIE_NAME, SHO_FAVOURITES_CALLOUT_COOKIE_NAME, SHO_SESSION_ACCESS_TOKEN_NAME, SHO_SESSION_REFRESH_TOKEN_NAME } from "../constants/app";
import { getCookieOptions } from "./cookieOptions";
var cookies = new Cookies();
export var getBonusFavouritesCalloutCookie = function getBonusFavouritesCalloutCookie() {
  return cookies.get(SHO_BONUS_FAVOURITES_CALLOUT_COOKIE_NAME) || null;
};
export var setBonusFavouritesCalloutCookie = function setBonusFavouritesCalloutCookie(expires) {
  cookies.set(SHO_BONUS_FAVOURITES_CALLOUT_COOKIE_NAME, 'hide', {
    expires: expires,
    path: '/'
  });
};
export var getBonusBannerCalloutCookie = function getBonusBannerCalloutCookie() {
  return cookies.get(SHO_BONUS_BANNER_CALLOUT_COOKIE_NAME) || null;
};
export var setBonusBannerCalloutCookie = function setBonusBannerCalloutCookie(expires) {
  cookies.set(SHO_BONUS_BANNER_CALLOUT_COOKIE_NAME, 'hide', {
    expires: expires,
    path: '/'
  });
};
export var getFavouritesCalloutCookie = function getFavouritesCalloutCookie() {
  return cookies.get(SHO_FAVOURITES_CALLOUT_COOKIE_NAME) || null;
};
export var setFavouritesCalloutCookie = function setFavouritesCalloutCookie(expires) {
  cookies.set(SHO_FAVOURITES_CALLOUT_COOKIE_NAME, 'hide', {
    expires: expires,
    path: '/'
  });
};
export var getShoSessionTokens = function getShoSessionTokens() {
  return {
    accessToken: cookies.get(SHO_SESSION_ACCESS_TOKEN_NAME) || null,
    refreshToken: cookies.get(SHO_SESSION_REFRESH_TOKEN_NAME) || null
  };
};
export var getShoSessionData = function getShoSessionData() {
  var _getShoSessionTokens = getShoSessionTokens(),
    accessToken = _getShoSessionTokens.accessToken;
  if (!accessToken) {
    return null;
  }
  var session = null;
  try {
    session = decode(accessToken);
  } catch (error) {
    return null;
  }
  return session;
};
export var createShoSession = function createShoSession(qToken, firstName) {
  var apollo = initApollo();
  return apollo.mutate({
    mutation: gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        mutation authenticateMember($qToken: String!, $firstName: String) {\n          authenticate(qToken: $qToken, firstName: $firstName) {\n            accessToken\n            refreshToken\n          }\n        }\n      "]))),
    variables: {
      qToken: qToken,
      firstName: firstName
    }
  }).then(function (_ref) {
    var authenticate = _ref.data.authenticate;
    var accessToken = authenticate.accessToken,
      refreshToken = authenticate.refreshToken;
    var expires = getDaysFromTodayDate(3650);
    cookies.set(SHO_SESSION_ACCESS_TOKEN_NAME, accessToken, {
      expires: expires,
      path: '/',
      secure: true
    });
    cookies.set(SHO_SESSION_REFRESH_TOKEN_NAME, refreshToken, {
      expires: expires,
      path: '/',
      secure: true
    });
    return authenticate;
  });
};
export var refreshShoSession = function refreshShoSession() {
  var _getShoSessionTokens2 = getShoSessionTokens(),
    shoRefreshToken = _getShoSessionTokens2.refreshToken;
  if (!shoRefreshToken) {
    return Promise.reject();
  }
  var apollo = initApollo();
  return apollo.mutate({
    mutation: gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        mutation refreshToken($shoRefreshToken: String!) {\n          refreshToken(shoRefreshToken: $shoRefreshToken) {\n            accessToken\n          }\n        }\n      "]))),
    variables: {
      shoRefreshToken: shoRefreshToken
    }
  }).then(function (_ref2) {
    var refreshToken = _ref2.data.refreshToken;
    var accessToken = refreshToken.accessToken;
    var expires = getDaysFromTodayDate(3650);
    cookies.set(SHO_SESSION_ACCESS_TOKEN_NAME, accessToken, {
      expires: expires,
      path: '/',
      secure: true
    });
    return accessToken;
  });
};
export var removeShoSessionTokens = function removeShoSessionTokens() {
  cookies.remove(SHO_SESSION_ACCESS_TOKEN_NAME, {
    path: '/'
  });
  cookies.remove(SHO_SESSION_REFRESH_TOKEN_NAME, {
    path: '/'
  });
};
export var setLslToken = function setLslToken(tokenObj) {
  var expiryTime = new Date(Date.now() + tokenObj.expires_in * 1000);
  cookies.set(LSL_TOKEN_NAME, tokenObj.access_token, getCookieOptions(expiryTime));
};