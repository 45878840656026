import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _taggedTemplateLiteral from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import initApollo from "../data/apollo";
import gql from 'graphql-tag';
import * as logger from "../utils/logger";
import isTokenExpiredError from "../utils/isTokenExpiredError";
import isTokenDecodeFailedError from "../utils/isTokenDecodeFailedError";
export var LOADING = 300000;
export var FETCHING_ERROR = 349999;
export var PAYLOAD_ERROR = 349998;
export var DUPLICATE = 340006;
export var NEEDS_CONFIRMATION = 340003;
export var ALREADY_LINKED = 340005;
export var NOT_LINKED = 340001;
export var NEEDS_AUTHENTICATING = 340002;
export var UNEXPECTED_ERROR = [340000, 340004];
export var SUCCESS = 200;
export var NOT_FOUND = 404;
export var ERROR = 505;
var initialState = {
  statusCode: LOADING
};
var actionTypes = {
  CHECK_STATUS_ERROR: 'CHECK_STATUS_ERROR',
  STATUS_LINKED: 'STATUS_LINKED',
  STATUS_SUCCESS_LINKED: 'STATUS_SUCCESS_LINKED',
  CHECK_STATUS: 'CHECK_STATUS',
  SET_STATUS: 'SET_STATUS',
  IN_LOADING: 'IN_LOADING',
  UPDATE_STATUS_FLAGS: 'UPDATE_STATUS_FLAGS',
  OTHERS: 'OTHERS'
};
export var memberStatusRequest = function memberStatusRequest() {
  return {
    type: actionTypes.CHECK_STATUS
  };
};
export var setMemberStatusRequest = function setMemberStatusRequest() {
  return {
    type: actionTypes.SET_STATUS
  };
};
export var memberStatusAlreadyLinked = function memberStatusAlreadyLinked() {
  return {
    type: actionTypes.STATUS_LINKED,
    statusCode: ALREADY_LINKED
  };
};
export var memberStatusSuccessLinked = function memberStatusSuccessLinked() {
  return {
    type: actionTypes.STATUS_SUCCESS_LINKED,
    statusCode: SUCCESS
  };
};
export var memberStatusOthers = function memberStatusOthers(statusCode) {
  return {
    type: actionTypes.OTHERS,
    statusCode: statusCode
  };
};
export var memberStatusError = function memberStatusError(statusCode) {
  return {
    type: actionTypes.CHECK_STATUS_ERROR,
    statusCode: statusCode
  };
};
export var updateMemberStatusFlags = function updateMemberStatusFlags(isExpired, decodeFailed) {
  return {
    type: actionTypes.UPDATE_STATUS_FLAGS,
    isExpired: isExpired,
    decodeFailed: decodeFailed
  };
};
export var setMemberStatus = function setMemberStatus(memberId, merchantId) {
  var body = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var isAuthenticated = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return function (dispatch) {
    dispatch(setMemberStatusRequest);
    if (!isAuthenticated && !memberId) {
      return;
    }
    var apollo = initApollo();
    var setEnrolmentStatusQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      query setEnrolmentStatusQuery(\n        $memberId: String\n        $merchantId: String\n        $body: EnrolmentStatusBody\n      ) {\n        setEnrolmentStatus(merchantId: $merchantId, memberId: $memberId, body: $body) {\n          errors {\n            status\n            code\n            message\n          }\n          status\n        }\n      }\n    "])));
    return apollo.query({
      query: setEnrolmentStatusQuery,
      variables: {
        memberId: memberId,
        merchantId: merchantId,
        body: body
      },
      fetchPolicy: 'network-only'
    }).then(function (_ref) {
      var _ref$data$setEnrolmen = _ref.data.setEnrolmentStatus,
        errors = _ref$data$setEnrolmen.errors,
        status = _ref$data$setEnrolmen.status;
      if (status === 200) {
        dispatch(memberStatusSuccessLinked());
        return;
      }
      if (status === 500) {
        dispatch(memberStatusError(FETCHING_ERROR));
      } else if (errors && errors.length) {
        dispatch(memberStatusError(errors[0].code));
      } else {
        dispatch(memberStatusError(FETCHING_ERROR));
      }
      logger.error("failed to set enrolment status: ".concat(status, ". Error: ").concat(JSON.stringify(errors)));
    }).catch(function (e) {
      var isExpired = isTokenExpiredError(e);
      var decodeFailed = isTokenDecodeFailedError(e);
      dispatch(updateMemberStatusFlags(isExpired, decodeFailed));
      dispatch(memberStatusError(FETCHING_ERROR));
      logger.error("failed to set enrolment status. Error: ".concat(JSON.stringify(e)));
    });
  };
};
export var fetchMemberStatus = function fetchMemberStatus(memberId, merchantId) {
  return function (dispatch) {
    dispatch(memberStatusRequest);
    var getEnrolmentStatusQuery = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getEnrolmentStatusQuery($memberId: String, $merchantId: String) {\n      getEnrolmentStatus(memberId: $memberId, merchantId: $merchantId) {\n        errors {\n          status\n          code\n          message\n        }\n        data\n        status\n      }\n    }\n  "])));
    var apollo = initApollo();
    return apollo.query({
      query: getEnrolmentStatusQuery,
      variables: {
        memberId: memberId,
        merchantId: merchantId
      },
      fetchPolicy: 'network-only'
    }).then(function (_ref2) {
      var _ref2$data$getEnrolme = _ref2.data.getEnrolmentStatus,
        errors = _ref2$data$getEnrolme.errors,
        status = _ref2$data$getEnrolme.status;
      if (status === 500) {
        dispatch(memberStatusError(FETCHING_ERROR));
        return FETCHING_ERROR;
      }
      if (errors && errors.length) {
        dispatch(memberStatusError(errors[0].code));
      }
      if (![SUCCESS, NOT_FOUND, 500, ALREADY_LINKED].includes(status)) {
        dispatch(memberStatusError(PAYLOAD_ERROR));
      }
      if (status === ALREADY_LINKED) {
        dispatch(memberStatusAlreadyLinked());
      }
      dispatch(memberStatusOthers(status));
      return status;
    }).catch(function () {
      dispatch(memberStatusError(FETCHING_ERROR));
    });
  };
};
export var partnerReducer = function partnerReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actionTypes.STATUS_LINKED:
    case actionTypes.CHECK_STATUS_ERROR:
    case actionTypes.OTHERS:
    case actionTypes.STATUS_SUCCESS_LINKED:
      return _objectSpread(_objectSpread({}, state), {}, {
        statusCode: action.statusCode
      });
    case actionTypes.CHECK_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        statusCode: LOADING
      });
    case actionTypes.UPDATE_STATUS_FLAGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isExpired: action.isExpired,
        decodeFailed: action.decodeFailed
      });
    default:
      return state;
  }
};