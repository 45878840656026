/** @format */

// getCookieOptions.js - provides standardized cookie options

/** @format */

/**
 * Returns standardized cookie options.
 * Sets the secure flag based on whether the environment is production.
 * @param {Date} expiryTime - The expiration time for the cookie.
 * @returns {Object} The cookie options.
 */
export var getCookieOptions = function getCookieOptions(expiryTime) {
  return {
    expires: expiryTime,
    path: '/',
    secure: true
  };
};